import React from 'react';
import { Id, toast } from 'react-toastify';

export function updateableToast(
  content: React.ReactNode | string,
  onUpdateToastId: (toastId: Id) => void,
  existingToastId: Nullable<Id>,
) {
  if (!!existingToastId && toast.isActive(existingToastId)) {
    toast.update(existingToastId, {
      render: content,
    });
  } else {
    const newToastId = toast(content);
    onUpdateToastId(newToastId);
  }
}
