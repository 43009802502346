import { useMemo } from 'react';

import Repayments from '@/lib/Repayments';

import useStore from './useStore';

export default function useRepaymentText(vehicle: StockItem) {
  const {
    general: { hideValuations },
    finance: {
      interestRate,
      loanLength,
      comparisonRate,
      financeBalloon,
      baseFees,
      afterTradeInPricesEnabled,
      weeklyPriceDisclaimer,
    },
  } = useStore('globals');
  const { tradeInEstimate, showTradeInPrice, tradeInEstimated } = useStore('appState');

  const tradeInPriceActive = useMemo(
    () => afterTradeInPricesEnabled && showTradeInPrice && tradeInEstimated && !hideValuations,
    [afterTradeInPricesEnabled, hideValuations, showTradeInPrice, tradeInEstimated],
  );
  const tradeInPrice = useMemo(() => (tradeInPriceActive ? tradeInEstimate : 0), [tradeInEstimate, tradeInPriceActive]);

  const repayment = useMemo(
    () =>
      vehicle?.price
        ? Repayments.calculate(
            vehicle.price,
            tradeInPrice,
            loanLength / 12,
            interestRate,
            Repayments.frequencyMap.week,
            financeBalloon,
            baseFees,
          )
        : null,
    [baseFees, financeBalloon, interestRate, loanLength, tradeInPrice, vehicle.price],
  );

  const adjustedPrice =
    tradeInPriceActive && vehicle && vehicle.price && vehicle.price > 0
      ? Math.max(vehicle.price - tradeInPrice, 0)
      : vehicle?.price || 0;

  const repaymentText = useMemo(
    () =>
      Repayments.text(weeklyPriceDisclaimer ?? '', {
        adjustedPrice: adjustedPrice,
        interestRate: interestRate,
        comparisonRate: comparisonRate,
        repayment: repayment || 0,
        financeBalloon: financeBalloon,
        tradeInEstimate: tradeInEstimate,
        tradeInPriceActive: tradeInPriceActive,
        loanLength: loanLength,
        baseFees: baseFees,
      }),
    [
      weeklyPriceDisclaimer,
      adjustedPrice,
      interestRate,
      comparisonRate,
      repayment,
      financeBalloon,
      tradeInEstimate,
      tradeInPriceActive,
      loanLength,
      baseFees,
    ],
  );

  return [repaymentText, tradeInPriceActive, adjustedPrice, repayment, interestRate] as const;
}
