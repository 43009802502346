import React, { useMemo } from 'react';

import { getMakeModel, getPrettyMileage, getRawFuelType, getShortTransmission } from '@/lib/StockItemHelper';

import styles from './styles.module.scss';

interface VCSpecsProps {
  vehicle: StockItem;
  specsToDisplay: Array<keyof StockItem>;
}

// Map any formatting functions
const formatMap: Partial<Record<keyof StockItem, (x: StockItem) => string>> = {
  make: getMakeModel,
  transmission: getShortTransmission,
  mileage: getPrettyMileage,
  fuelType: getRawFuelType,
};

const VCSpecs = ({ vehicle, specsToDisplay }: VCSpecsProps) => {
  const specs = useMemo(() => {
    return specsToDisplay
      .map((p) => {
        if (formatMap[p]) {
          return formatMap[p]!(vehicle);
        }
        return vehicle[p];
      })
      .filter((f) => !!f);
  }, [specsToDisplay, vehicle]);

  if (!specs.length) return null;

  return (
    <div className={styles.vcSpecs}>
      {specs.map((spec, i) => (
        <span key={i}>{spec?.toString()}</span>
      ))}
    </div>
  );
};

export default VCSpecs;
