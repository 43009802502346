import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import useStore from '@/hooks/useStore';
import { getIcon } from '@/lib/IconHelper';

import styles from './styles.module.scss';

interface Props {
  vehicle: StockItem;
}

const DistinguishedIcons = ({ vehicle }: Props) => {
  const { distinguishedStock } = useStore('globals');

  const items = useMemo(
    () =>
      distinguishedStock.filter((item) => vehicle.hashtags && item.hashtag && vehicle.hashtags.includes(item.hashtag)),
    [distinguishedStock, vehicle.hashtags],
  );

  if (items.length === 0) return null;
  return (
    <div className={styles.distinguishedIcons}>
      {items.map((item) => (
        <div key={item.uid} className={styles.icon}>
          {item.icon && getIcon(item.icon)}
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default observer(DistinguishedIcons);
