import React from 'react';

import styles from './styles.module.scss';

interface VCLabelInterface {
  Icon?: React.FC<React.SVGAttributes<SVGElement>>;
  text: string;
}

const VCLabel = ({ Icon, text }: VCLabelInterface) => {
  return (
    <div className={styles.vcLabel}>
      {Icon && <Icon />}
      {text}
    </div>
  );
};

export default VCLabel;
