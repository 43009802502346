import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { getDistance } from 'geolib';

import useStore from '@/hooks/useStore';
import { IconMapPin } from '@/images/icons/tabler-icons';
import { capitalizeName } from '@/lib/utils';

import styles from './styles.module.scss';

interface VCLocationProps {
  vehicle: StockItem;
  separator: string;
}
const VCLocation = ({ vehicle, separator }: VCLocationProps) => {
  const dealershipsMap = useStore('dealershipsMap');
  const { sortLocation } = useStore('directoryStore');
  const {
    customization: { displayCityState },
  } = useStore('globals');

  const distance = useMemo(() => {
    const dealership = dealershipsMap.dealershipsArray.find(
      (dealership) => dealership.dealeridentifier === vehicle.dealerID,
    );
    if (dealership && sortLocation?.lat && sortLocation.lng) {
      const value = getDistance(
        { latitude: dealership.latitude, longitude: dealership.longitude },
        { latitude: sortLocation.lat, longitude: sortLocation.lng },
      );
      return (value / 1000).toFixed(2);
    }

    return null;
  }, [dealershipsMap.dealershipsArray, sortLocation, vehicle.dealerID]);

  const displayLocation = useMemo(
    () =>
      displayCityState
        ? capitalizeName(dealershipsMap.getVehicleRegion(vehicle))
        : dealershipsMap.dealerCodeToSuburb[vehicle.dealerID],
    [dealershipsMap, displayCityState, vehicle],
  );

  if (!displayLocation && !distance) return null;

  return (
    <div className={styles.vcLocation}>
      {!!displayLocation && (
        <div className={styles.in}>
          <IconMapPin width={16} height={16} />
          In {displayLocation}
        </div>
      )}
      {!!distance && (
        <>
          <span>{separator}</span>
          <div className={styles.distance}>{distance}km away</div>
        </>
      )}
    </div>
  );
};

export default observer(VCLocation);
