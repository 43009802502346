import { ImageLoaderProps } from 'next/image';

import PublicEnvironment from '@/stores/PublicEnvironment';

const cdnDomain = 'vehicle-cdn.autoleague.cloud';

export const idomThumbImgLoad = () => {
  if (PublicEnvironment.get('MOCK_GRAPHQL_SERVER') || PublicEnvironment.get('MOCK_GRAPHQL')) return undefined;
  return ({ src, width, quality = 90 }: ImageLoaderProps) =>
    `https://${cdnDomain}/_next/image/?url=${src}&w=${width}&q=${quality}`;
};

export const idomFullImgLoad = () => {
  if (PublicEnvironment.get('MOCK_GRAPHQL_SERVER') || PublicEnvironment.get('MOCK_GRAPHQL')) return undefined;
  return ({ src, width, quality = 90 }: ImageLoaderProps) =>
    `https://${cdnDomain}/_next/image/?url=${src}&w=${width}&q=${quality}`;
};

export const impelImgLoad = (allowWidth = false) => {
  if (PublicEnvironment.get('MOCK_GRAPHQL_SERVER') || PublicEnvironment.get('MOCK_GRAPHQL')) return undefined;
  if (allowWidth)
    return ({ src, width, quality = 75 }: ImageLoaderProps) =>
      `https://${cdnDomain}/_next/image/?url=${src}&w=${width}&q=${quality}`;
  return ({ src, quality = 75 }: ImageLoaderProps) =>
    `https://${cdnDomain}/_next/image/?url=${src}&w=1080&q=${quality}`;
};
