class Repayments {
  static frequencyMap = {
    week: 1,
    fortnight: 14 / 7,
    month: 52 / 12,
    year: 52,
  };

  static getRepayment(
    loanAmount = 0,
    loanLength = 5,
    interestRate: number,
    baseFees: number,
    frequency: number = Repayments.frequencyMap.week,
  ) {
    const numPayments = (loanLength * 52) / frequency;
    if (interestRate === 0) {
      // console.warn('No interest rate provided');
      return loanAmount / numPayments;
    }
    let repayment: Nullable<number> = 0;
    if (loanAmount > 0) {
      const totalLoanAmount = loanAmount + baseFees;
      const interest = (interestRate / 100 / 52) * frequency;
      const x = Math.pow(1 + interest, numPayments);
      repayment = Math.max(0, (totalLoanAmount * interest * x) / (x - 1));
    }

    if (Number.isNaN(repayment) || !Number.isFinite(repayment)) {
      repayment = 0;
    }

    return repayment;
  }

  static calculate(
    loanAmount = 0,
    tradeInValue = 0,
    loanLength = 5,
    interestRate?: number,
    frequency: number = Repayments.frequencyMap.week,
    balloon = 0,
    baseFees = 0,
  ) {
    if (!interestRate) {
      // console.warn('No interest rate provided');
      return null;
    }
    let cost: Nullable<number> = 0;
    if (loanAmount > 0) {
      const totalLoanAmount = loanAmount - tradeInValue + baseFees;
      const numPayments = loanLength * 52;
      const i = interestRate / 100 / 52;
      const discountFactor = ((1 + i) ** numPayments - 1) / (i * (1 + i) ** numPayments);

      cost = Math.max(0, (totalLoanAmount * (1 - balloon / 100 / (1 + i) ** numPayments)) / discountFactor) * frequency;
    }

    if (Number.isNaN(cost) || !Number.isFinite(cost)) {
      cost = null;
    } else {
      cost = Math.ceil(cost);
    }

    return cost;
  }

  static reverse(
    weeklyRepayment = 0,
    tradeInValue = 0,
    loanLength = 5,
    interestRate?: number,
    frequency: number = Repayments.frequencyMap.week,
    balloon = 0,
    baseFees = 0,
  ) {
    if (!interestRate) {
      return 0;
    }

    let cost: Nullable<number> = 0;
    if (weeklyRepayment > 0) {
      const numPayments = loanLength * 52;
      const i = interestRate / 100 / 52;
      const discountFactor = ((1 + i) ** numPayments - 1) / (i * (1 + i) ** numPayments);

      cost = (discountFactor * weeklyRepayment) / frequency + tradeInValue;
      cost = cost * (1 + balloon / 100);
    }

    if (Number.isNaN(cost) || !Number.isFinite(cost)) {
      cost = 0;
    } else {
      cost = Math.floor(cost / 500) * 500;
    }

    return Math.max(cost - baseFees, 0);
  }

  static text(
    repaymentPerWeekDisclaimer = '',
    weeklyDisclaimerVariables: WeeklyDisclaimerParams = {
      adjustedPrice: 0,
      interestRate: 0,
      comparisonRate: 0,
      repayment: 0,
      financeBalloon: 0,
      tradeInEstimate: 0,
      tradeInPriceActive: false,
      loanLength: 0,
      baseFees: 0,
    },
  ) {
    const {
      adjustedPrice,
      interestRate,
      comparisonRate,
      repayment,
      financeBalloon,
      tradeInEstimate,
      tradeInPriceActive,
      loanLength,
      baseFees,
    } = weeklyDisclaimerVariables;

    return (
      repaymentPerWeekDisclaimer &&
      repaymentPerWeekDisclaimer
        .replace(/{{repayment}}/g, repayment ? repayment!.toLocaleString() : '0')
        .replace(
          /{{deposit}}/g,
          tradeInPriceActive ? tradeInEstimate.toLocaleString('en-AU', { minimumFractionDigits: 2 }) : '0',
        )
        .replace(
          /{{interestRate}}/g,
          interestRate ? interestRate.toLocaleString('en-AU', { minimumFractionDigits: 2 }) : 'unavailable',
        )
        .replace(
          /{{comparisonRate}}/g,
          comparisonRate ? comparisonRate.toLocaleString('en-AU', { minimumFractionDigits: 2 }) : 'unavailable',
        )
        .replace(/{{loanAmount}}/g, adjustedPrice ? adjustedPrice.toLocaleString() : '0')
        .replace(/{{loanLength}}/g, loanLength ? loanLength.toString() : 'unavailable')
        .replace(
          /{{balloon}}/g,
          (((financeBalloon || 0) / 100) * adjustedPrice).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
        )
        .replace(/{{baseFees}}/g, baseFees.toLocaleString('en-AU', { minimumFractionDigits: 2 }))
    );
  }

  static getPrincipalPerYear(
    loanAmount: number,
    loanLength: number,
    interestRate: number,
    baseFees: number,
    frequency: number,
  ) {
    const repayment = this.getRepayment(loanAmount, loanLength, interestRate, baseFees, frequency);
    const numPayments = Math.round(((loanLength - 1) * 52) / frequency);
    const interestPerTime = (interestRate / 100 / 52) * frequency;

    let balance = loanAmount;
    const principals: number[] = [];
    for (let i = 1; i <= numPayments; i++) {
      const interest = balance * interestPerTime;
      const principal = repayment - interest;

      // totalInterest += interest;
      balance = balance - principal;

      // sum per year
      if (i % Math.round(52 / frequency) === 0) {
        principals.push(balance);
      }
    }

    return principals;
  }

  static getMaxAmountValueOnChart(totalRepayment: number) {
    return Math.ceil(totalRepayment / 6000) * 6000 * 1.06;
  }

  static getClipPath(principals: number[], loanAmount: number) {
    if (principals.length >= 1) {
      const percents: string[] = [];
      principals.forEach((p, idx) => {
        percents.push(`${((idx + 1) / (principals.length + 1)) * 100}% ${100 - (p / loanAmount) * 100}%`);
      });

      return `polygon(0 100%, 0 0, ${percents.join(', ')}, 100% 100%)`;
    }

    return 'polygon(0 100%, 0 0, 100% 100%)';
  }

  static formatNumber(value: number) {
    return `${value.toFixed(2)}`;
  }
}

export default Repayments;
