import React, { useMemo } from 'react';
import { ToastContentProps } from 'react-toastify';
import { inflect } from 'inflection';

import HeartIcon from '@/images/icons/heart.svg';

import styles from './styles.module.scss';

export type FavouritesToastMsgType = 'count' | 'clearAll';

type Props = {
  msgType: FavouritesToastMsgType;
  carNumber?: number;
  undoCallback?: () => void;
};

const FavouritesMsg = ({ msgType, carNumber = 1, undoCallback }: Partial<ToastContentProps> & Props) => {
  const content = useMemo(() => {
    switch (msgType) {
      case 'count':
        return (
          <>
            <div>{`${carNumber} ${inflect('cars', carNumber)} in your favourites`}</div>
            {carNumber > 0 && (
              <a className={styles.viewFavourites} href="/favourites">
                View
              </a>
            )}
          </>
        );
      case 'clearAll':
        return (
          <>
            <div>You cleared your favourites</div>
            <button className={styles.undo} onClick={undoCallback}>
              Undo
            </button>
          </>
        );
      default:
        return;
    }
  }, [msgType, carNumber, undoCallback]);
  return (
    <div key={`${msgType}-${carNumber}`} className={styles.favouritesMsg}>
      <HeartIcon />
      {content}
    </div>
  );
};
export default FavouritesMsg;
